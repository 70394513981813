import React, {useState, useEffect, useCallback, useRef} from 'react'
import {Route, Routes, BrowserRouter, Navigate } from 'react-router-dom'

import './App.css'
import {Home} from './components/Home'
import {SignUp} from './components/Sign/SignUp'
import {Members} from './components/Member/Members'
import {Registering} from './components/Member/Registering'
import {Courses} from './components/Course/Courses'
import {Classes} from './components/Classe/Classes'
import {Lessons} from './components/Lesson/Lessons'
import {Teams} from './components/Team/Teams'
import {Booking} from './components/Booking/Booking'

import {Managing} from './components/Managing/Managing'
import {Planning} from './components/Planning/Planning'
import {Performing} from './components/Performing/Performing'

import Header from './components/Header'
import Footer from './components/Footer'
import Global from './components/Global'
import SignIn from './components/Sign/SignIn'

import {Settings} from './components/Setting/Settings'
import {Persons} from './components/Managing/Persons'
import {Subjects} from './components/Managing/Subjects'
import Reading from './components/Reading/Reading'
import Memorizing from './components/Memorizing/Memorizing'
import Meditating from './components/Meditating/Meditating'

function App() {
  const global = new Global()
  const connected = global.connected()

  const [search, Search] = useState("")
  const [course, Course] = useState(1)
  const [etat, Etat] = useState("")
  const [role, Role] = useState(0)
  const [layout, Layout] = useState(window.matchMedia('(orientation:landscape)').matches)

  const current = sessionStorage.getItem("routeCurrent") 
  const route = useRef(current ?? '/') // "/signin"

  const onResize = useCallback(event => {
    if(route.current!=="/memorizing") { //  
      let value=window.matchMedia('(orientation:landscape)').matches    
      Layout(value)
      //console.log('window.matchMedia.matches',value) //; alert(value)
    }
  }, [])

/*
  useEffect(() => {
          window.addEventListener("resize", onResize);
          return () => {
              window.removeEventListener("resize", onResize);
          };
  }, [onResize, layout])
*/

  return (
<BrowserRouter>
    <Header global={global} onSearch={Search} onRole={Role} route={route} layout={layout} />

    <Routes>
      <Route path='/' element={ <Home connected={connected} />} exact />
      <Route path='/signup' element={ <SignUp global={global} />} />
      <Route path='/signin' element={ <SignIn apiUrl={global.apiUrl} show={true} />} />

      <Route path='/member' element={ <Members global={global} search={search} />} />
      <Route path='/registering' element={ <Registering global={global} />} />
      <Route path='/team' element={ <Teams global={global} search={search} role={role} Etat={Etat} />} />

      <Route path='/classe' element={ <Classes global={global} id={2} />} />        
      <Route path='/lesson' element={ <Lessons global={global} />} />

      <Route path='/managing' element={ <Managing global={global} layout={layout} search={search}  />} />
      <Route path='/planning' element={ <Planning global={global} role={role} search={search} />} />
      <Route path='/booking' element={ <Booking global={global} />} />
      <Route path='/performing' element={ <Performing global={global} search={search} Etat={Etat} />} />

      <Route path='/setting' element={ <Settings global={global} search={search} role={role} Etat={Etat} />} />
      <Route path='/person' element={ <Persons global={global} layout={layout} search={search} />} />
      <Route path='/subject' element={ <Subjects global={global} role={role} search={search} />} />
      <Route path='/reading' element={ <Reading global={global} layout={layout} search={search} />} />

      <Route path='/course' element={ <Courses onCourse={Course} global={global} role={role} />} /> 
      <Route path='/memorizing' element={ <Memorizing search={search} onEtat={Etat} role={role} course={course} layout={layout} />} />
      <Route path='/meditating' element={ <Meditating search={search} onEtat={Etat} role={role} course={course} layout={layout} />} />

    </Routes>

    <Footer etat={etat}/>

  </BrowserRouter>

  )
}

export default App;

