import React,{Component} from 'react'
import './Course.css'
import {Table} from 'react-bootstrap'
import CourseModal from './CourseModal'
import {ToNotify} from '../Shared/ViewControl'
import {OnAdd, OnCRUD} from '../Shared/ActionControl'
import {ToDate,ToId,ToClick} from '../Shared/ViewControl'
import { ClasseDetail } from '../Classe/ClasseDetail'


export class CourseDetail extends Component {

    constructor (props){
        super(props);
        this.state = {items:[], modalShow:false, itemId:0, mode:0, message:''};
        this.entity='Course'
        //  16px	1rem (base)
    }

    componentDidMount(){

        if( this.props.global.connected())
            this.Load()
    }

    Load() {
        this.setState({loading:true})
        this.props.global.GET(this.entity)
        .then(data => this.setState({items:data, loading:false}))
        .catch(error => console.log({ error }))
    }    

    Delete(id)  {
        this.props.global.DELETE(this.entity, id)
        .then(()=> this.Load())
    }

    Message(message='') {
        this.setState({message:message})
    }
    
    modalClose =() => {
        this.setState({modalShow:false})
        this.Load()
    }

    componentWillUnmount() {
        this.setState({items:null})        
    }
    
    render() {

        const {items}=this.state
        let global = this.props.global

        let filteredItems = items.filter(
    
            (item)=>{
                return (item.Name+item.Description+item.Manager+global.toString(item.Date)).indexOf(this.props.search)!==-1;
            }
        );

 
        return (

<div className="FullScreen">
<Table striped hover bordered size="sm">
<thead>
    <tr>
        <th>رقم</th>
        <th>الدورة</th>
        <th>ملاحظات</th>        
        <th>المديرة</th>
        <th>المستوى</th>
        <th>تاريخ البدء</th>
        <th>الانتهاء</th>
        <OnAdd onClick={()=>this.setState({modalShow:true, itemId:0, mode:1})}/>
    </tr>
</thead>

<tbody style={{ justifyContent: 'flex-end' }} key='CourseDetail'>
    {
        filteredItems.map(item=>
            <tr key={item.Id}>

<ToId value={item.Id} onClick={()=> this.setState({itemId:item.Id, mode:5})} />
<ToClick value={item.Id} text={item.Name} onClick={()=> this.setState({itemId:item.Id, mode:5})} />
<td>{item.Description}</td>
<td>{item.Manager}</td>
<td>{item.Level}</td>
<ToDate value={item.StartDate} />
<ToDate value={item.EndDate} />

<OnCRUD 
    onInfo={()=> this.setState({modalShow:true, itemId:item.Id, mode:2})} 
    onEdit={()=> this.setState({modalShow:true, itemId:item.Id, mode:3})} 
    onDel={()=> this.Delete(item.Id)} 
/>
            </tr>
            )
    }
</tbody>
</Table>

<ToNotify message={this.state.message} onClose={()=>this.Message()} />
{this.state.modalShow ?
        <CourseModal
        global={this.props.global}
        action={{id:this.state.itemId, crud:this.state.mode, show:true}}
        Message={this.Message}
        onHide={this.modalClose}
        />
: null}

{this.state.itemId>0  ?
<ClasseDetail global={this.props.global} parent={this.state.itemId}  />
: null }

</div>


        )
    }
}