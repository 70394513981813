import React, { useState, useEffect, useLayoutEffect } from 'react'
import './Managing.css'
import { Focused } from '../Timer/Timers'
import {meSplitter} from '../Shared/meGlobal'
import Activities from '../Shared/Activities'
import {Performing} from '../Performing/Performing'

export const Managing = ({global, layout, search}) => {
        const activity=5
        const [planning, Plan] = useState(0)
        const [perform, Perform] = useState(0)
        const [reported, Reported] = useState(6)
        const [note, Note] = useState(0)

        const main = new meSplitter("Side", "MainContainer", layout)

        useLayoutEffect (()=>{  
                main.selectorAll() 
                //alert(main.items.length)      
            },[])

              
        Focused(1003, activity, Reported )

        const onPlan = (id) => {
                Plan(id)
        }

        const onPerform = (id) => {
                Perform(id)
        }

        const onNote = (id) => {
                Note(id)
        }

        useEffect(() => {
                sessionStorage.setItem("routeCurrent","/managing")
                //alert('useEffect '+itemId)
        }, [planning])    


return (

<>
                
{ global.connected() ?

<div className="Home" id="MainContainer"
onMouseDown={(e)=>{main.mouseDown(e)}} 
onMouseMove={(e)=>{main.mouseMove(e)}} 
onMouseUp={(e)=>{main.mouseUp(e)}}
>
{ layout ?
<>
<div className="side vside" id="SideActivities" style={{width: '40%'}}>
        <Activities activity={activity} element={1003} onElement={onNote} reported={reported} search={search} />
</div>

<div className="side vside" id="SidePlanning" style={{width: '60%'}}>
        <Performing global={global} onPlan={onPlan} onPerform={onPerform} planning={planning} search={search} />
</div>
</>
:
<>
<div className="side hside" id="SidePlanning" style={{width: '60%'}}>
        <Performing global={global} onPlan={onPlan} onPerform={onPerform} planning={planning} search={search} />
</div>
<div className="side hside" id="SideActivities" style={{width: '40%'}}>
        <Activities activity={activity} element={1003} onElement={onNote} reported={reported} search={search} />
</div>


</>

}        
</div>
: <h5>Not Connected</h5> }
</>
        )
}
