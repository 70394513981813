import React, {useState, useEffect} from 'react';
import {Row, Col, Navbar} from 'react-bootstrap';
import {NavLink} from 'react-router-dom'; 
import {Navigation} from './Navigation';
import SignIn from './Sign/SignIn'
import SearchControl from './Shared/SearchControl'

const Header = ({global, onSearch, onRole, route, layout}) => {
    const [showing, setShow] = useState(false)
    const connected = global.connected()

    useEffect(() => {
        setShow(false)
    }, [])    


    const Login = (e) => {
        setShow(true)
    }

    return (
        
        <div className="Header" id="Header">
            <Row className="navbar navbar-custom" expand="lg">
            <Col sm={6}>
            { connected ?

<Navigation  global={global} onRole={onRole} route={route} layout={layout} />
:
                <Navbar >
                    <NavLink className="navbar-custom" to="/signup" >
                        <i className="fa fa-edit"> التسجيل</i></NavLink>  

                    <NavLink className="navbar-custom" style={{marginLeft:"5rem"}} to="/" onClick={Login} >
                        <i className="fa fa-sign-in"> الدخول</i></NavLink>  
                </Navbar>
    }

            </Col>             
            <Col sm={3} style={{textAlign:'center'}} >
            <SearchControl onSearch={onSearch} />
            </Col>

            <Col sm={3} className="top-right">
                <a href="/">
                    <img className="Logo" src="logo.png" alt="الرئيسية" />
                </a>
            </Col>            
            </Row>

{ showing && !connected ?
<SignIn 
    apiUrl={global.apiUrl}
    show={true}
    onHide={()=>{setShow(false)}}
/>
: null}                     
        </div>
        
        
    )
}

export default Header;