import React, { useState, useEffect } from 'react'
import {Col, Row} from 'react-bootstrap'
import './Classe.css'
import {ToNotify} from '../Shared/ViewControl'
import {OnAction, OnExpand} from '../Shared/ActionControl'
import { viewDate } from '../Shared/Formating'
import ClasseModal from './ClasseModal' 

const Classes = ({global, course, search, onClasse}) => {
    const entity='Classe'
    const [loaded, Load] = useState(false)
    const [items, Items] = useState([])
    const [notify, Notify] = useState({open:false, message:''})
    const [action, Action] = useState({id:0, crud:0, show:false})
    const [fetched, Fetched] = useState(false)

    useEffect(() => {
        let request = entity
        if(course && course>0)
            request=`Course/Detail/${course}`
       
        global.GET(request)
        .then(data => {
            Items(data)
            //alert(data.length)
            Load(true) 
        })
        .catch(error => {
            console.log(error)
            Notify({open:true,message:error})
        })
        
    }, [loaded])

    useEffect(()=>{
        //alert(`onClasse ${action.id} ?`, )
        onClasse(action.id)
    },[action.id])

    let filteredItems = items.filter(
        (item)=>{
            return (item.Course+item.Lesson+item.Description+item.Master).indexOf(search)!==-1;
    })

    const onHide = () => {
        if(fetched) {
            Load(false)
            Fetched(false)
        }
        Action({...action,show:false, crud:0})
    }

    return (
<>

{ loaded ?
    <div className="Classes">

    {
        filteredItems.map(item=>
            <Classe key={`Classe-${item.Id}`}
            item={item}
            icon="./images/tadabor.png" 
            onAction={Action} 
          />
            )
    }

{action.show ?
        <ClasseModal
        global={global}
        action={action}
        onHide={onHide}
        Fetched={Fetched}
        />
: null}

</div>
: <h6>{entity} is loading...</h6> }

<ToNotify 
message={notify.message}
onClose={()=>Notify({open:false, message:''})}
/> 

</>
        )
}


const Classe = ({item, icon, onAction}) => {
    const cstyle={fontSize: '1rem', justifyContent:'center', alignItems:'center', fontWeight: 'bold', color:'white' }
    //alert(name)

    return (
        <div className='Classe' >
            <Col style={{width: "calc(100% - 3px)"}} onClick={()=>onAction({id:item.Id, crud:0, show:false})}>
                <Row style={{...cstyle, color:'gray'}}>{item.Course}</Row>
                <Row style={{...cstyle, fontSize: '1.5rem'}}>{item.Lesson}</Row>
                <Row style={{...cstyle, fontSize: '1.2rem', color:'cyan', margin:'1rem'}}>{item.Master}</Row>

                <Row style={{...cstyle, fontSize: '0.8rem', color: 'black'}}>{item.Description}</Row>

                <Row style={{marginTop:'0.5rem'}}>
                <Col sm={6} style={{...cstyle, color: 'cyan'}}>{viewDate(item.StartDate, false)}</Col>
                <Col sm={6} style={{...cstyle, color: 'cyan'}}>{viewDate(item.EndDate, false)}</Col>
                </Row>
                
            </Col>
            <OnAction 
            onEdit={ ()=>onAction({id:item.Id, crud:3, show:true}) } 
            onExpand={ ()=>onAction({id:item.Id, crud:2, show:true}) }
            />             
        </div>
    )
}

export {Classes}

/*
<OnExpand onClick={()=>onAction({id:item.Id, crud:2, show:true})} />
<div className="center">
                <img src={icon} alt={item.Master+": "+item.Lesson} />
            </div>

*/