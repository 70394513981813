import React,{useState, useEffect, useLayoutEffect} from 'react'
import './Performing.css'

import {PerformingDetail} from './PerformingDetail'
import {PlanningTable} from '../Planning/PlanningTable'
import {meSplitter} from '../Shared/meGlobal'

export const Performing = ({global,onPlan,onPerform,planning, search, Etat}) => {
    const [itemId, setItemId] = useState(0)

    const splitter = new meSplitter("PerformingSide", "Performing", false)

    useLayoutEffect (()=>{  
        splitter.selectorAll() 
            sessionStorage.setItem("routeCurrent","/performing")
            //alert(detail.items.length)      
        },[])

    const onSelect = (event) => {        
        //console.log("Performing.onSelect event.target", event.target); //alert(event.target.value)
        onPlan && onPlan(event.target.value)
        setItemId(event.target.value)        
    }


    return (

            <>
                
{ global.connected() 
?
<div className="Sides" id="PerformingContainer" style={{height:'90vh'}}
onMouseDown={(e)=>{splitter.mouseDown(e)}} 
onMouseMove={(e)=>{splitter.mouseMove(e)}} 
onMouseUp={(e)=>{splitter.mouseUp(e)}}
>

    <div className="side hside" id="PerformingSidePlanning" style={{height: '50%'}}>
        <PlanningTable global={global} onPlan={onSelect} mode={9} />
    </div>
    <div className="side hside" id="PerformingSideDetail" style={{height: '50%'}}>
        <PerformingDetail global={global} planning={planning} onPerform={onPerform} />
    </div>

</div>
: 
<h1>{global.message}</h1> }
</>
        )
    
}