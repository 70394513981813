import React from 'react'
import {useNavigate } from 'react-router-dom'
import {Form, Button, ButtonToolbar, Row} from 'react-bootstrap'
import { CSVDownload } from "react-csv";
import { saveAs } from 'file-saver';

const OnHome = ({onHide}) => {
  
    const navigate = useNavigate()
  
    const onHome = () => {
      navigate('/')
    }
    
    return (
      <Button variant="outline-danger" onClick={onHide??onHome}><i className="fa fa-close"> إلغاء</i></Button>
    )  
}

const OnAction = ({onExport, onInfo, onValid, onBlock, onEdit, onSave, onCancel, onDelete, onCreate, onRefresh, onExpand, onPerform, title, rstyle={justifyContent:'right'}}) => {

    const bstyle={ width: '1.8rem', aspectRatio:'1 / 1', display: 'block', margin:'0.1rem' }
    const istyle={ display: 'flex', justifyContent: 'center' }

    return (
    <Row style={{ margin:'0', alignItems: 'center', position: 'relative', padding:'0rem', height:'2rem', ...rstyle }}>        
        <ButtonToolbar>

{ onExport ?
        <Button variant='outline-success' alt="Export" style={{height: '1.8rem', display: 'block', margin:'0.1rem' , textAlign:'left'}}
        onClick={onExport} 
        ><i className="fa fa-file-excel-o" style={istyle}></i></Button>
: null}

{ onInfo ?
        <Button variant='outline-info' alt="Infos" style={{height: '1.8rem', display: 'block', margin:'0.1rem' , textAlign:'left'}}
        onClick={onInfo.onClick} 
        >{onInfo.title}<i className="fas fa-stream" style={istyle}></i></Button>
: null}

{ onValid ?
        <Button variant='outline-success' alt="Valid" style={bstyle}
        onClick={onValid} 
        ><i className="fa fa-check" style={istyle}></i></Button>
: null}

{ onBlock ?
        <Button variant='outline-danger' alt="Block" style={bstyle}
        onClick={onBlock} 
        ><i className="fa fa-ban" style={istyle}></i></Button>
: null}


{ onPerform ?
        <Button variant='outline-primary' alt="Perform" style={bstyle}
        onClick={onPerform} 
        ><i className="fa fa-calendar-times-o" style={istyle}></i></Button>
: null}

{ onExpand ?
        <Button variant='outline-info' alt="Expand" style={bstyle}
        onClick={onExpand} 
        ><i className="fa fa-expand" style={istyle}></i></Button>
: null}

{ onRefresh ?
        <Button variant='outline-dark' alt="Refresh" style={bstyle}
        onClick={onRefresh} 
        ><i className="fa fa-refresh" style={istyle}></i></Button>
: null}

{ onCreate ?
        <Button variant='outline-primary' alt="Create" style={bstyle}
        onClick={onCreate} 
        ><i className="fa fa-plus" style={istyle}></i></Button>
: null}

{ onEdit ?
        <Button variant="outline-warning" alt="Edit" style={bstyle}
        onClick={onEdit}
        ><i className="fa fa-edit" style={istyle}></i></Button>
: null }

{ onCancel ?
            <Button variant="outline-secondary" alt="Cancel" style={bstyle} 
            onClick={onCancel}
            ><i className="fa fa-close" style={istyle}></i></Button>
: null }

{ onSave ?
            <Button variant="outline-success" alt="Save" style={bstyle}
            onClick={onSave}
            ><i className="fa fa-save" style={istyle}></i></Button>
:null}

{ onDelete ?            
    <Button variant="outline-danger" alt="Delete" style={bstyle}
            onClick={onDelete}
            ><i className="fa fa-trash" style={istyle}></i></Button>
: null }
        </ButtonToolbar>
        {title ? <h6 style={{width:'50%', textAlign: 'left' }}>{title}</h6> : null}
        
    </Row>
    )
}

const OnExport = ({ onFetch, data, name }) => {
  
    if (data && data.length>0) {
      return (
        <CSVDownload
          className="csv-download"
          target="_self"
          filename={name}
          data={data}
        />
      )
    } else {
      return (
        <Button variant='outline-success' alt="Export" style={{height: '1.8rem', display: 'block', margin:'0.1rem' , textAlign:'left'}}
        onClick={onFetch} 
        ><i className="fa fa-file-excel-o" style={{ display: 'flex', justifyContent: 'center' }}></i></Button>

        )
    }
  }

const OnDownload = async (data, filename) => {
    try {
        if (data) {
            if (filename.indexOf('.csv') === -1) {
                filename += '.csv'
            }

            let blob = new Blob(['', data],{ type: 'text/csv;charset=utf-8' });
            saveAs(blob, filename);

            return true;
        }
    } catch (error) {
      console.log('error', error)
    }
}
  
const OnAdd = ({onClick}) => {
    return (
        <th style={{ width:"8rem" }}>
        <ButtonToolbar style={{ display: 'flex', justifyContent: 'center' }}>
        <Button variant='primary' onClick={onClick} alt="Add"
        ><i className="fa fa-plus"></i></Button>
        </ButtonToolbar>
    </th>
    )
}

const OnCRUD = ({onInfo, onEdit, onDel, onAdd}) => {

    //let color="inherit"
    const bstyle={ width: '2rem', display: 'block', margin:'0.1rem' }
    const istyle={ display: 'flex', justifyContent: 'center' }

    return (
    <td style={{ width: "auto"  }}>
        <ButtonToolbar style={{ display: 'flex', justifyContent: 'center' }}>
            
            {onInfo ?
                <Button variant="outline-info" alt="Info" style={bstyle}
                onClick={onInfo}
                ><i className="fa fa-info" style={istyle}></i></Button> 
            : null }

            {onEdit ?
                <Button variant="outline-warning" alt="Edit" style={bstyle}
                onClick={onEdit}
                ><i className="fa fa-edit" style={istyle}></i></Button>
            : null }

            {onAdd ?
                <Button variant='outline-primary' alt="Add" style={bstyle}
                onClick={onAdd}
                ><i className="fa fa-plus" style={istyle}></i></Button>
            : null }

            {onDel ?
                <Button variant="outline-danger" alt="Delete" style={bstyle}
                onClick={onDel}
                ><i className="fa fa-trash" style={istyle}></i></Button>
            : null }

        </ButtonToolbar>
    </td>
    )
}

const OnSubmit = ({crud='1', label}) => {
    const saving="134"

    return (    
        saving.includes(crud) 
        ?
            <Form.Group style={{justifyContent:"center", alignItems:"center", display: "flex"}}>
                <Button variant="success" type="submit" style={{width:"auto", fontSize:"1.1rem", margin:"1.5rem"}}>
                <i className="fa fa-save"></i> {label ?? (crud==="4" ? "تأكيد الحذف"  : " حفظ") }    
                </Button>
            </Form.Group>
        :   null
    )
}

const OnExpand = ({onClick}) => {
    return (
<Button className="mr-2" variant={"outline-info"}  
style={{ position:'inherit', width: '6%', aspectRatio:'1 / 1', top:0, right:0, margin:0 }}
    onClick={onClick}
    ><i className={"fa fa-expand"} style={{display: 'flex', justifyContent: 'center'}}></i>
    </Button>
    )
}

export {OnExport, OnAdd, OnSubmit, OnCRUD, OnAction, OnExpand, OnHome}

