import React, { useState, useEffect, useLayoutEffect } from 'react'
import './Managing.css'
import {Notes} from '../Note/Notes'
import { Timers, Focused } from '../Timer/Timers'
import { MemberGrid } from '../Member/MemberGrid'
import {meSplitter} from '../Shared/meGlobal'
import Api from '../Api'

export const Persons = ({global, layout, search, onSearch}) => {
        const activity=5
        const [team, Team] = useState(0)
        const [note, Note] = useState(0)
        const [reported, Reported] = useState(0)

        const main = new meSplitter("Side", "MainContainer", layout)
        const detail = new meSplitter("Side", "DetailContainer", false)

        useLayoutEffect (()=>{  // like useEffect but synchronously after all DOM
                main.selectorAll()  
                detail.selectorAll()       
            },[])
        
              
        Focused(1002, activity,  Reported )

        const onNote = (id) => {
            Note(id)
        }

        const onMember = (id) => {
                alert(id)
        }

        useEffect(() => {
                //alert('useEffect '+itemId)
        }, [team])   

        const onMouseUp = (event) => {
                main.mouseUp(event, Api)
                detail.mouseUp(event, Api)
        }
        

return (

<>
                
{ global.connected() ?
<>
{ layout ?

<div className="Home" id="MainContainer"
            onMouseDown={(e)=>{main.mouseDown(e)}} 
            onMouseMove={(e)=>{main.mouseMove(e)}} 
            onMouseUp={onMouseUp}
        >

<div className="side" id="SideDetail" style={{width: '30%'}}>

<div className="Home" id="DetailContainer"
            onMouseDown={(e)=>{detail.mouseDown(e)}} 
            onMouseMove={(e)=>{detail.mouseMove(e)}} 
            onMouseUp={onMouseUp}
        >
                <div className="side" id="SideNote" style={{height: '40%'}}>
                <Notes activity={activity} search={search} element={1002} onNote={onNote}  />
                </div>
                <div className="side" id="SideTimer" style={{height: '60%'}}>
                <Timers element={1002} activity={5} reported={reported}/>
                </div>
</div>
</div>


<div className="side" id="SideMembers" style={{width: '70%'}}>
        <MemberGrid global={global} onMember={onMember} />
</div>
        </div>
:
<div className="Home" id="DetailContainer"
            onMouseDown={(e)=>{detail.mouseDown(e)}} 
            onMouseMove={(e)=>{detail.mouseMove(e)}} 
            onMouseUp={onMouseUp}
        >
        <div className="side hside" id="SideMembers" style={{height: '40%'}}>
                <MemberGrid global={global} onMember={onMember} />
        </div>

        <div className="side hside" id="SideNote" style={{height: '30%'}}>
                <Notes activity={activity} search={search} element={1002} onNote={onNote}  />
        </div>
        <div className="side hside" id="SideTimer" style={{height: '30%'}}>
                <Timers element={1002} activity={5} reported={reported}/>
        </div>
</div>

}
</>

: <h5>Not Connected</h5> }

</>
        )
}

