import React,{Component} from 'react'
import {Row} from 'react-bootstrap'
import {Services, Service} from './Service/Services'

export class Home extends Component{

    constructor(props){
        super(props)
        this.state = {loading:false}
    }

    render() {

        return (

            <div className="mt-11 d-flex justify-content-center">

{   this.props.connected
?
<Services />
:
<div className="Home">
<h1>مرحبًا، الرجاء الدخول أو التسجيل</h1>

<div className="Services" >
<Service name="الدخول" 
  icon="./images/hifd.png" 
  to="/signin"
/>

<Service name="طلب التسجيل" 
  icon="./images/hifd.png" 
  to="/signup"
/>

</div>

</div>
}
            </div>
        )
    }
}