import React, { useState, useEffect } from "react"
import {Modal,Button,Form,Row,Col} from 'react-bootstrap'
import {CountryControl} from '../Shared/CountryControl'
import {ToNotify, Loading} from '../Shared/ViewControl'
import {InText, InOption} from '../Shared/InputControl'
import Api, {Cookie} from "../Api"
import Config, {sign as iSign} from '../Config/ar'
import {OnHome} from '../Shared/ActionControl'
import './Sign.css'


const SignIn = ({apiUrl, show, onHide=null}) => {

  const [notify, Notify] = useState('')

  const [message, Message] = useState('')
  const [valid, setValid] = useState(false)
  const [submiting, setSubmit] = useState(false)
  const [code, setCode] = useState('')

  const mePhone=Cookie('mePhone')??''
  //alert(mePhone)
  const meCountry=Cookie('meCountry')??1
  const meRole=Cookie('meRole')??1
  const [data, setData]= useState({country:meCountry, phone:mePhone, code:"", app: Api.defaults.domain, from: 2, mode:1, role:meRole})

useEffect(() => {
  if(valid) {
    if(data.mode===1) {
      if(data.phone.length===10){
        //onSubmit()
      }
    }
    else if(data.mode===2 && data.code.length===5) {
      onSubmit()
    }
  }
  else {
    if(data.mode===1){
      setValid(data.phone.length===10 && data.role>=1)
    }
  }
}, [valid, data])  

const onChangeData = e => {
    setData({...data, [e.target.name]: e.target.value})
 }

 
 const onRole = e => {
  const value = e.target.value
  document.cookie = `meRole=${value}`
  setData({...data, role: value})
}

const onCountry = e => {
  const value = e.target.value
  document.cookie = `meCountry=${value}`
  setData({...data, country: value})
}

const onPhone = e => {
  e.preventDefault();
  const value = e.target.value
  setData({...data, phone: value})

  setValid(false)
  if(value.length===10) {
      const phoneRegex = /^\d+$/;
      if (phoneRegex.test(value)) {
        document.cookie = `mePhone=${value}`
        setValid(true)

      //let stored = Cookie('mePhone') ; alert(stored)
      }
  }
}

const onCode = (e) => {
  e.preventDefault();
  onChangeData(e)

  setValid(false)
  if(e.target?.value?.length>4) {
      setValid(true)
  }  
}

const onSubmit=(e)=> {
    //e.preventDefault();
    //console.log(apiUrl,"data:",data)

    let message = "هناك مشكلة مع رقم الهاتف هذا"
    setSubmit(true)
    fetch(`${apiUrl}/signin`,{
        method:'POST',
        headers:{
            'Accept':'application/json',
            'Content-Type':'application/json'
        },
        body:JSON.stringify(data)
    })
    .then(response => response.json())
        .then(
            (result)=>
            {
              if(result.message) {
                Message(result.message)    
                if(result.message.includes("Salaam"))// && result.role)
                {
                  let risaala = result.message.replace("Salaam","السلام عليكم")
                  risaala = risaala.replace("please confirm with your Telegram code received by","\nيرجى التأكيد بالرقم الذي توصلتم به في تلغرام\n")

                  setCode(risaala) 
                  if(data.mode===1) {
                      setData({...data, mode: 2})
                      result.message =+ "مرحبا"                                            
                  }
                  else if(data.mode===2) {
                      localStorage.setItem('Akademy',JSON.stringify({
                          connected:true, ...result}   
                      ))
                      setData({...data, mode: 3})
                      Notify(risaala)
                  }                    
                }
                else {
                  let error = result.message

                  if(parseInt(result.status)===405) {
                    error = iSign.error.role
                  }
                  Message(error)
                  Notify(error)
                }                
                      
              }
              else {
                Notify(result.message)
              }
              setSubmit(false)    
            },
            (error)=>{
              Message(message)
              Notify(`error ${error.message} ?`)
            }
        )
  }



  return (

<div className="sign">

<Loading etat={iSign.label.login}  loading={submiting} />

<ToNotify
  message={notify}
  onClose={()=>{
    Notify('')
    window.location.reload()
  }}
/>    { data.mode<3 
      ?
        <Modal
          show={show}
          onHide={onHide}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          animation={false}
          centered
          style={{marginTop:'1.5rem'}}
        >

            <Modal.Header>
            <Col sm={6} >
              <Modal.Title id="contained-modal-title-vcenter" style={{paddingTop:'3rem'}}>
              <h2 style={{ display: 'flex', justifyContent: 'flex-end', color:'darkgreen' }}>{iSign.label.login}</h2> 
              </Modal.Title>
              </Col>
              <Col sm={3}>
                <img className="Icon" src={Config.logo} alt={data.app} style={{marginLeft:"1%", width:"9rem", height:"9rem"}}/>
              </Col>
            </Modal.Header>

            <Modal.Body>

  <Row style={{ display: 'flex', justifyContent:'center', marginTop:'1rem', textAlign:'center' }}>
     
     <Col sm={8} >
          
      <div className="degrade-green" style={{border:"12px solid darkgreen", borderRadius:"15px", padding:"1rem", justifyContent:"center"}}>         
            
           { data.mode===1 ?
<>
          <h3 style={{color:"blue"}}>{code}</h3> 
              <InOption entity={'Role'} id={data.role} onChange={onRole} label={'تسجيل الدخول بدور'} /> 
              <CountryControl apiUrl={apiUrl} label='البلد' onChange={onCountry}  itemId={data.country}/> 
              <InText name="Phone" required label="رقم الهاتف" onChange={onPhone} value={data.phone} />

          <h3 style={{color:"red", backgroundColor:"yellow"}}>{message}</h3>
</>              
              :
<>
          <h3 style={{color:"blue", margin:"1rem"}}>{"يرجى التأكيد بالرمز الذي توصلتم به في تلغرام"}</h3> 
              <InText name="code" required label="الرمز من تلغرام" onChange={onCode} />
              <a target="_blank" rel="noopener noreferrer" href="https://telegram.me/akademymabot" style={{fontWeight:"bold", fontSize:"2rem"}}>
                <img className="Telegram" src="images/telegram.png" alt="@AkademyMaBot" style={{marginLeft:"10%", width:"5rem"}} />
                {"للذهاب إلى تلغرام"}
              </a>
          <h3 style={{color:"orabge"}}>{message}</h3>
</>
           }

        </div>

           <Form.Group id="SignIn" style={{justifyContent:"center", alignItems:"center", display: "flex", marginTop:"2rem"}}>

           {valid ?
           <Button onClick={onSubmit} size="lg" 
                    style={ valid && !submiting
                            ? {width:"50%",backgroundColor:'green',color:'yellow'} 
                            : {width:"50%",backgroundColor:'blue',color:'white', pointerEvents:'none', cursor:'not-allowed'}}>
              {iSign.label.login}</Button>
          :null}
           </Form.Group>

      </Col>
 </Row>
 </Modal.Body>
            <Modal.Footer>
              <OnHome onHide={onHide} />
            </Modal.Footer>
          </Modal>
      : 
      null
      }
 
    </div>
  )
}
export default SignIn

