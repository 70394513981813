import React, {useState, useEffect} from 'react';
import Config from './Config/ar'

const Footer = ({etat}) => {

    const [time, Time] = useState("");

    useEffect(() => {
        const options = {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric'
        }
    
        setTimeout(() => {
            Time((new Date()).toLocaleDateString('ar-MA', options))
            //console.log(time)
        }, 1000)
        return () => { clearTimeout(time)}
    },[time])


    return (
        <div className="Footer">
            <i>{time} - {etat?.length>0?etat:Config.title}</i>
        </div>
    )
}

export default Footer;

/*
<script>
function showTime() {
    var d = new Date();
    document.getElementById("clock").innerHTML = d.toLocaleTimeString();
}
setInterval(showTime, 1000);
</script>
 
<p>Time: <span id="clock"></span></p>
*/