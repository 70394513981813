import React, {useState, useEffect} from 'react';
import {Modal,Button,Row,Col,Form} from 'react-bootstrap';
import {InText, InDetail} from '../Shared/InputControl'
import OptionsControl from '../Shared/OptionsControl';
import {toDate, addDays, toMinutes} from '../Shared/Formating'
import {OnSubmit} from '../Shared/ActionControl'
import {ModalHeader, ModalFooter} from '../Shared/ModalControl'
import {ToNotify} from '../Shared/ViewControl'
import {DurationControl } from '../Shared/Appointment';
import {Fetch} from '../Api'

export const PerformingModal = ({global, action, planning, onHide}) => {
    const entity='Performing'
    const readOnly = action?.crud===2
    const [item, Item] = useState(null)
    const [loaded, Load] = useState(false)
    const [notify, Notify] = useState({open:false, message:''})

    useEffect(() => {
        onLoad()
    }, [loaded])

    const onLoad = () => {
        //console.log('PerformingModal.GetItem', action)
        if(action.show) {
            if(action?.id > 0) {
                global.GetItem(entity, action.id)
                .then((data) => {
                    Item(data)
                    Load(true)
                    //console.log('PerformingModal', data)
                })
                .catch(error => {
                    Notify({open:true, message:error})   
                })
            }
            else if(action?.crud===1) {
                Item({Id:0, PlanningId:planning, ActivityId:1, Description:'',Date:addDays(0), Duration:300})
                Load(true)
            }
        }
    }

    const onStat = (name,value)=>{
        if(name==="duration"){
            let secs=toMinutes(value)*60
            //alert("toSeconds "+secs)
            Item({...item, Duration:secs})
        }
    }
        
    const onFetch = () => {
        //console.log('PerformingModal.onFetch', item)
        //alert('PerformingModal.onFetch '+ action.crud)
        Fetch(entity, item, action.crud, Load)
    }

    const onChange = (e) => {
        e.preventDefault()
        Item({...item, [e.target.name]: e.target.value})
        //console.log('PerformingModal onChange', item)
    }

        return(
            <div className="container">
<ToNotify 
message={notify.message}
onClose={()=>Notify({open:false,message:''})}
/>   
            <Modal
            show={action.show}
            onHide={onHide}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >

<ModalHeader title={"معلومات الأداء"} id={action.id} />

            <Modal.Body>

 <Row>
     <Col sm={8} >
         {
loaded && item ? 
         <Form onSubmit={ readOnly?null: onFetch}>

<OptionsControl entity={'Planning'} id={item.PlanningId} onChange={onChange} readOnly={readOnly} label='تخطيط'/> 
<OptionsControl entity={'Activity'} id={item.ActivityId} onChange={onChange} readOnly={readOnly} label='نشاط'/> 

            <Form.Group controlId="PerformingDate">
              <Form.Control
                type="date"
                name="Date"
                placeholder="تاريخ الأداء"
                defaultValue={toDate(item.Date)}
                readOnly={readOnly}
              />
            </Form.Group>

<DurationControl   
label={"المدة"}
  name="duration"
  type="number"
  defaultvalue={item?.Duration}
  disabled={readOnly} 
  onDuration={onStat}
  /> 

             <InDetail name="Description" value={item?.Description ?? ''} onChange={onChange} readOnly={readOnly} label={"ملاحظات"} />

             <OnSubmit crud={action.crud} />

         </Form>
: <div>{entity} is loading...</div>
        }
     </Col>
 </Row>

            </Modal.Body>
            <ModalFooter onHide={onHide} />

          </Modal>
          </div>  
    )
}
