

const Config = {
    bismi:'بِسۡمِ ٱللهِ ٱلرَّحۡمَـٰنِ ٱلرَّحِيمِ',
    salaam:'ٱلسَّلَامُ عَلَيْكُمْ',
    title:'أكاديمية التجويد',
    logo : 'logo.png',
    home : 'الرئيسية'

}

const home = {
    title : 'مرحبًا، الرجاء الدخول أو التسجيل'
}

const header = {
    signup : "التسجيل",
    signin : "الدخول",    
    signout : "خروج"
}

const sign = {
    title:'التسجيل في أكاديمية التجويد',
    name:'طلب التسجيل',
    info:'إذا لم تكوني عضوة بعد',
    confirmed:"تم ربط هذا الهاتف من قبل يمكنك الدخول الآن",
    confirming:"تم ربط هاتفك بنجاح عليك الدخول الآن",

    requirement: {
        telegram:"يعتمد هذا التطبيق كليا على تلغرام المثبت على هاتفك الذكي",
        required:"معلومات ضرورية",
        optional:"معلومات اختيارية",
        infos:'الرجاء إدخال الاسم ورقم الهاتف'
    },

    error: {
        name:'ينبغي أن يكون الأسم عربياً',
        phone: {
            regex:"رقم الهاتف غير صحيح",
            start:"يجب أن يبدأ رقم الهاتف بـ 0",
            found:"رقم الهاتف هذا غير موجود"
        },
        group:"من الضروري التأكيد على مقدار الحفظ للانظمام للمجموعة",
        problem:"مشكلة في التسجيل",
        telegram:"يجب إنشاء حساب تلغرام قبل المتابعة",
        role:"هذا الدور غير مسموح به هنا؟"

    },

    telegram: {
        verification: 'هل لديك حساب تلغرام ؟',
        start:"في تلغرام، إذهب إلى AkademyMaBot",
        elapsed:"نحن في انتظار الرمز منذ ؟؟ ثانية",
        risaala: "فضلا أرسلي هذا الرقم عبر حساب تلغرام الذي سيفتح بالنقر فوق شعار تلغرام أو فوق الزر أسفله"

    },

    label: {
        infos: 'معلومات إضافية أو أسئلة',
        submit: 'إرسال طلب التسجيل',
        login: "تسجيل الدخول"
    },

    status : {
        hello : "مرحبا"
    },

    in : {
        code:'يرجى التأكيد بالرمز الذي توصلتم به في تلغرام',
        title:'الرمز من تلغرام',
        label:'00000'
    }
}

export default Config
export {header, home, sign}