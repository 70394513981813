import React, { useState, useLayoutEffect } from 'react'
import '../Managing/Managing.css'
import './Reading.css'
import ReadControl from './ReadControl'
import {Parts} from './Parts'

import {PartControl} from './Parts'
import {Focused} from '../Timer/Timers'

import {meSplitter} from '../Shared/meGlobal'
import Activities from '../Shared/Activities'

const Reading = ({global, layout, search}) => {
    const activity=1
        const [page, Page] = useState(1)
        const [part, Part] = useState({id:1, page:1, pages:21})
        const [note, Note] = useState(0)
        const data = {parts:30, pages:604, path:"https://moshaf.akademy.ma/safahaat/"}
        const [reported, Reported] = useState(6)
        
        const splitter = new meSplitter('Side','Reading',layout)
        useLayoutEffect (()=>{  
            splitter.selectorAll() 
        })

        Focused(page, activity, Reported)
    
        const onPrev = () => {
            Page(page>0 ? page-1 : data.pages)
        }
    
        const onNext =() => {
            Page(page<data.pages ? page+1 : 0)
        }

        const onPart = (selected) => {
            //console.log('Reading.onPart', selected); alert(selected.id)
            if(selected.id>0 && selected.id<=data.parts){
                Page(selected.page)
                Part(selected)
            }
        }

        const onPage = (selected) => {
            //console.log('Reading.onPage', selected); alert(selected)
            if(selected>=0 && selected<=data.pages)
                Page(selected)
        }

        const onNote = (id) => {
            Note(id)
        }

return (

data.parts>0 && page>0 ?

    layout ?
    <div className="Home" id="ReadingContainer" 
        onMouseDown={(e)=>{splitter.mouseDown(e)}} 
        onMouseMove={(e)=>{splitter.mouseMove(e)}} 
        onMouseUp={(e)=>{splitter.mouseUp(e)}}
        >
        <div className="side vside" id="SideActivities" style={{width: '30%'}}>
            <Activities activity={activity} element={page} onElement={onNote} reported={reported} search={search} />
        </div>
        <div className="side vside" id="SideReadControl" style={{width: '40%', overflow: 'hidden'}}>
            <ReadControl path={data.path} part={part} page={page} onNext={onNext} onPrev={onPrev} onPart={onPart} onPage={onPage} />
        </div>
        <div className="side vside" id="SideParts" style={{width: '30%'}}>
            <Parts path={data.path} part={part} page={page} onPart={onPart} onPage={onPage} />
        </div>
    </div>
    :
    <div className="Sides" id="ReadingContainer" 
    onMouseDown={(e)=>{splitter.mouseDown(e)}} 
    onMouseMove={(e)=>{splitter.mouseMove(e)}} 
    onMouseUp={(e)=>{splitter.mouseUp(e)}}
    >

        <div className="side hside" id="SidePartControl" style={{height: '17%'}}>
            <PartControl part={part} name='parts' onPart={onPart} />
        </div>
        <div className="side hside" id="SideReadControl" style={{height: '83%'}}>
            <ReadControl path={data.path} part={part} page={page} onNext={onNext} onPrev={onPrev} onPart={onPart} onPage={onPage} />
        </div>
    </div>
: 
<h5>Not Connected</h5> 
     )
}

export default Reading
