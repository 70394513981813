import React, { useLayoutEffect } from 'react'
import {Notes} from '../Note/Notes'
import { Timers } from '../Timer/Timers'
import {meSplitter} from '../Shared/meGlobal'

const Activities = ({activity, element, onElement, reported, search}) => {

    const detail = new meSplitter("LogingSide", "Loging", false)

    useLayoutEffect (()=>{  
            detail.selectorAll()       
        },[])
    

return (
    <div className="Sides" id="LogingContainer"
            onMouseDown={(e)=>{detail.mouseDown(e)}} 
            onMouseMove={(e)=>{detail.mouseMove(e)}} 
            onMouseUp={(e)=>{detail.mouseUp(e)}}
        >

        <div className="side hside" id="LogingSideNote" style={{height: '30%'}}>
            <Notes activity={activity} search={search} element={element} onNote={onElement}  />
        </div>
        <div className="side hside" id="LogingSideTimer" style={{height: '30%'}}>
            <Timers element={element} activity={activity}  reported={reported}/>
        </div>
    </div>


     )
}

export default Activities
