import React, { useState, useEffect } from "react"
import {Modal,Button,Form} from 'react-bootstrap'
import {ToNotify} from '../Shared/ViewControl'
import {InText} from '../Shared/InputControl'
import ReadControl from "./ReadControl"
import {Api} from '../Memorizing/Moshaf'

const ReadModal = ({mode=1, path, part, page, show, onHide, onPage}) => {

const [valid, Valid] = useState(false)
const [submiting, setSubmit] = useState(false)
const [data, setData]= useState({aaya:1, soora:2, hizb:1, thomon:1 })
const [message, Notify] = useState('')
const [selected,Select] = useState(page)
const pages=604

const action= (mode===9)?"Update aaya":""

const onNext = () => {
    Select(selected<pages ? selected+1 : 0)
}

const onPrev = () => {
    Select(selected>0 ? selected-1 : pages)
}

useEffect(() => {
    //setData({...data, phone: e.target.value})
    onPage(selected)
    //console.log('mode and selected', mode, selected)

}, [selected])  

useEffect(() => {
  if(valid) {
    if(mode===9 && data.aaya.length>1 && data.soora.length>1) {
      onSubmit()
      onNext()
      var aaya=document.getElementById('aaya')
      if(aaya) {
        aaya.value=data.aaya.substring(0,1)
        aaya.focus()
      }
    }
  }
}, [valid,data])  

  //alert(apiUrl)
const onChangeData = e => {
    e.preventDefault()
    setData({...data, [e.target.name]: e.target.value})
    //console.log("data:",data)
}

const copyText = (text) => {
  if(navigator.clipboard)
    navigator.clipboard.writeText(text)
}

const onAaya = e => {
  Valid(false)
  if(e.target?.value?.length===3)
  {
    let old=data
    let input=e.target.value
    onChangeData(e)

      const numRegex = /^\d+$/;
      if (numRegex.test(input)) {

        //alert(e.target.value +" : "+ old.aaya)
        //console.log('onAaya', old, data)
        if( input < old.aaya || input===0 )
        {
          var soora=document.getElementById('soora')
          if(soora) {
            soora.value = (parseInt(soora.value)+1).toString()
            //document.getElementById('soora').onchange()
          
            //var event = new Event('change');
            //soora.dispatchEvent(event);
          }
        }        
        Valid(true) 
      }
  }
  else if (e.key === 'Enter') {
    Valid(true) 
  }
}

const onSoora = (e) => {
  onChangeData(e)
  //alert(e.target.value)
}

const onSubmit=(e)=> {
    console.log("submit data: ",data)
    setSubmit(true)
    fetch(`${Api.defaults.baseURL}/joze/${selected}/${data.soora}/${data.aaya}`,{
        method:'POST',
        headers: Api.defaults.headers,
        body:JSON.stringify(data)
    })
    .then(response => response.json())
        .then(
            (result)=>
            {
              if(result.message) {
                Notify(result.message)
                setSubmit(false)          
              }
              else{
                Notify("Error api: result message is null?")
              }
            },
            (error)=>{
                Notify(error.message)
            }
        )
  }


  return (

<div className="outlet">

<ToNotify 
message={message}
onClose={()=>Notify('')}
/>   
   
      { mode>0 && mode<=9 
      ?
        <Modal
          show={show}
          onHide={onHide}
          size="lg"
          aria-labelledby = "القرآن الكريم"
          animation={false}
          style={{marginTop:'-1rem', height:'100%'}}
          centered
        >
            <Modal.Body style={{ width:"100%", height:"95vh", display: 'flex', justifyContent: 'center' }}>
            
            <ReadControl path={path} part={part} page={selected} onNext={onNext} onPrev={onPrev} modal={true} />


{ mode===9 ?
     
      <Form style={{border:"12px solid green", borderRadius:"10px", padding:"1rem", position:'absolute', top:'50%', left:'25%', width:'50%', height:'40%', opacity:'0.7'}}>
                      
              <InText name="soora" required onChange={onSoora} />
              <InText name="aaya" required onChange={onAaya} />
              <InText name="hizb" required onChange={onChangeData} />
              <InText name="thomon" required onChange={onChangeData} />

        <h5 style={{color:"orange", margin:"1rem"}}>{message}</h5>

           <Button onClick={onSubmit} size="lg" 
                    style={ valid && !submiting
                            ? {width:"50%",backgroundColor:'green',color:'yellow'} 
                            : {width:"50%",backgroundColor:'darkgray',color:'gray', pointerEvents:'none', cursor:'not-allowed'}}>
                {action}
            </Button>

      </Form>
: null }

    <Button className="mr-2" variant={"outline-danger"}  
        style={{ position:'absolute', width: '8%', aspectRatio:'1 / 1', top:'2%', left:'90%' }}
        onClick={onHide}
    >
        <i className={"fa fa-close"} style={{display: 'flex', justifyContent: 'center'}}></i>
    </Button>

  </Modal.Body>
</Modal>
      : 
      null
      }

</div>
)}

export default ReadModal
