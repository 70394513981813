import React, { useState, useEffect, useLayoutEffect } from 'react'
import './Managing.css'
import {Planning} from '../Planning/Planning'
import { Classes } from '../Classe/Classes'
import {meSplitter} from '../Shared/meGlobal'

export const Subjects = ({global, role, search}) => {
        const [classe, Classe] = useState(0)
        const splitter = new meSplitter('Side','Subjects',false)

        const onClasse = (id) => {
                //alert(id)
                Classe(id)
        }

        useLayoutEffect (()=>{  
                splitter.selectorAll() 
                //alert(splitter.items.length)      
            },[])
    
        useEffect(() => {
                //alert('useEffect '+itemId)
        }, [classe])    


return (

<>
                
{ role>2 ?
    <div className="Sides" id="SubjectsContainer" style={{height:'90vh'}}
    onMouseDown={(e)=>{splitter.mouseDown(e)}} 
    onMouseMove={(e)=>{splitter.mouseMove(e)}} 
    onMouseUp={(e)=>{splitter.mouseUp(e)}}
>

<div className="side hside" id="SideClasses" style={{height: '50%'}}>
<Classes global={global} search={search} onClasse={onClasse} />

</div>
<div className="side hside" id="SidePlanning" style={{height: '50%'}}>
<Planning global={global} role={role} classe={classe} search={search} />
</div>
</div>


: <h5>Not Authorized</h5> }

</>
        )
} 


/*
 style={{ width:"max-content", height:"max-content", margin:0, padding:0}}
*/